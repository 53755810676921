<template>
  <Layout ref="main">
    <!-- <PageHeader :title="title" :items="items" /> -->
    <div class="row">
      <div class="col-12">
        <div class="my-3 text-muted">
          <div class="row">
            <div class="col-12">
              <div class="form-group card mb-3 p-3">
                <label class="form-check-label mb-2 font-size-14 fw-normal text-muted">
                <i class="mdi mdi-card-account-details-outline text-primary me-1 font-size-16"></i> {{$t('siteLang.IdentificationCard')}}</label>
                
                <input type="tel" class="form-control" maxlength="16" v-model="profileInfo.icNumber" disabled
                :placeholder="$t('siteLang.IdentificationCard')">
              </div>
            </div>  <!-- col-12-->
            <div class="col-12 col-md-6 mb-0 text-center">
              <div class="mb-3 rounded bg-white card p-3 position-relative">
                <label class="form-check-label mb-2 font-size-14 fw-normal text-muted"> <i class="mdi mdi-card-account-details-outline text-primary me-1 font-size-16"></i> {{$t('siteLang.FrontIDCard')}}</label>
                <img
                  v-if="profileInfo.icFrontImg"
                  class="rounded img-fluid mb-2"
                  :src="profileInfo.icFrontImg"
                />
              </div> 
            </div>
            <!-- col-12 col-md-6  -->
            <div class="col-12 col-md-6 mb-0 text-center">
              <div
                class="mb-3 p-3 rounded bg-white card position-relative">
                <label class="form-check-label mb-2 font-size-14 fw-normal text-muted"> <i class="mdi mdi-card-account-details-outline text-primary me-1 font-size-16"></i>  {{$t('siteLang.BackIDCard')}}</label>
                <img
                  v-if="profileInfo.icBackImg"
                  class="rounded img-fluid mb-2"
                  :src="profileInfo.icBackImg"
                />
              </div> 
            </div><!-- col-12 col-md-6  -->
           </div><!-- row  -->
        </div>
      </div>
    </div>
    <Lottie :path="''" :title="$t('siteLang.IdentityInformation')" :info="'Loading..Please Wait.'" :show="showLottie" ref="lottieFunc" />
    <Common ref="commonFunc"/>
  </Layout>
</template>
<script>
import Layout from "@/views/layouts/main";
// import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import axios from "axios";
import Swal from "sweetalert2";
/**
 * Starter page
 */
export default {
  components: { 
    Layout, 
    //PageHeader, 
    Lottie,
    Common
  },
  page() {
    return {
      title: this.$t('siteLang.IdentityInformation'),
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    }
  },
  data() {
    return {
      loading:false,
      accessToken:'',
      accessEmail:'',
      accessPhone:'',
      profileInfo:"",
      showLottie:false,
      title: '',
      items: [
        {
          text: appConfig.title,
          to: "/",
        },
        {
          text: appConfig.title,
          to: "/",
        },
        {
          text: '',
          active: true,
        },
      ],
    };
  },
  middleware: "authentication",
  mounted(){
    this.$refs.main.changeVerticalTopBar("my profile",true)
    this.$refs.main.setPageTitle(this.$t('siteLang.IdentityInformation'))
    this.title = this.$t('siteLang.MyProfile')
    this.items[1].text = this.$t('siteLang.MyProfile') 
    this.items[1].to = "/my-profile"
    this.items[2].text = this.$t('siteLang.IdentityInformation') 
  
    // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
    // this.$refs.main.changeVerticalTopBar("bill",true)
    // this.$refs.main.setShowFooterCert(false)
    // this.$refs.main.setPageTitle('title')
    this.profileInfo = this.$refs.commonFunc.getProfileInfo()
    
  },
  created(){
    //this.showLottie = true
  },
  methods:{
   
    getData(){
        this.$Progress.start();
        this.showLottie = true
        this.loading = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("phone",  this.accessPhone);
        axios({
            method: "post",
            url: appConfig.DemoAPI,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
            } 
            else if (resData.status == 401){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                this.$refs.commonFunc.clearData()
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.loading = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    }
  }
};
</script>